import React from "react";
import withSizePositionAngle from "components/withSizePositionAngle";


const _CowHead = () => {
    return (
        <g transform="translate(-1.012829 -1.028228)">
          <path d="M30.89,13a1,1,0,0,0-.69-.51l-5.79-1.21c2.48-4,1.79-9.16,1.75-9.4a1,1,0,0,0-1.93-.19c-.8,2.28-4.32,4.69-5.85,5.66a8.75,8.75,0,0,0-4.76,0c-1.53-1-5-3.38-5.85-5.66a1,1,0,0,0,-1.9,3.19c0,.24-.73,5.41,1.75,9.4L1.8,12.47a1,1,0,0,0,-.69,.51,1,1,0,0,0,0,.86A7.33,7.33,0,0,0,7,18V19.8a5,5,0,0,0-.48,1.49l-.18,1.29a3,3,0,0,0,2.35,3.35L9,27.69A3.86,3.86,0,0,0,12.76,31h6.48a3.86,3.86,0,0,0,3.81-3.31l.26-1.76a3,3,0,0,0,2.35,-3.35l-.18-1.29A5,5,0,0,0,25,19.8V18a7.33,7.33,0,0,0,5.92-4.18A1,1,0,0,0,30.89,13ZM24.22,5.24a11.54,11.54,0,0,1-1.44,4.86,9.16,9.16,0,0,0-2.19-1.83A22,22,0,0,0,24.22,5.24ZM23,16v1.67A5,5,0,0,0,20.53,17H11.47A5,5,0,0,0,9,17.67V16a7,7,0,0,1,14,0ZM11.41,8.27A9.16,9.16,0,0,0,9.22,10.1,11.54,11.54,0,0,1,7.78,5.24,22,22,0,0,0,11.41,8.27ZM3.62,14.13l3.78-.79A9,9,0,0,0,7,16H7A5.22,5.22,0,0,1,3.62,14.13ZM19.24,29H12.76a1.88,1.88,0,0,1-1.84-1.59L10.72,26H21.28l-.2,1.41A1.88,1.88,0,0,1,19.24,29Zm4.21-5.35a1,1,0,0,1-.76,.35H9.31a1,1,0,0,1-.76-.35,1,1,0,0,1-.23-.79l.18-1.28a3,3,0,0,1,3,-2.58h9.06a3,3,0,0,1,3,2.58l.18,1.28A1,1,0,0,1,23.45,23.65ZM25,16h0a9,9,0,0,0-.4-2.66l3.78,.79A5.25,5.25,0,0,1,25,16Z"/>
          <path d="M19.5,14.6a1,1,0,0,0,1-1V13a1,1,0,0,0-2,0v.6A1,1,0,0,0,19.5,14.6Z"/>
          <path d="M12.5,14.6a1,1,0,0,0,1-1V13a1,1,0,0,0-2,0v.6A1,1,0,0,0,12.5,14.6Z"/>
          <path d="M18.5,20.2a1,1,0,0,0-1,1v.6a1,1,0,0,0,2,0v-.6A1,1,0,0,0,18.5,20.2Z"/>
          <path d="M13.5,20.2a1,1,0,0,0-1,1v.6a1,1,0,0,0,2,0v-.6A1,1,0,0,0,13.5,20.2Z"/>
        </g>
    );
}

const CowHead = withSizePositionAngle(_CowHead, 29.980819, 29.971772);

export default CowHead;