import { Fragment } from "react";
import { SinglePoint, MultiplePoints, ManyPoints, ThousandPoints, NoFunction } from "../../../../content/wiskunde/functies/grafiek/drawings/plots.tsx";
import * as React from 'react';
export default {
  Fragment,
  SinglePoint,
  MultiplePoints,
  ManyPoints,
  ThousandPoints,
  NoFunction,
  React
};