import WandelingInPark from "../../../../content/fysica/grootheden_eenheden/eenheden_omzetten/drawings/wandeling_in_park";
import DirkKruist from "../../../../content/fysica/grootheden_eenheden/eenheden_omzetten/drawings/dirkKruist";
import WatIs1m84, { MeterIs100Cm, DirkInCm } from "../../../../content/fysica/grootheden_eenheden/eenheden_omzetten/drawings/watIs1m84";
import MeterNaarDecimeter from "../../../../content/fysica/grootheden_eenheden/eenheden_omzetten/exercises/meterNaarDecimeter";
import { DirkZegtTijd } from "../../../../content/fysica/grootheden_eenheden/eenheden_omzetten/drawings/dirkKruist";
import MinNaarUur from "../../../../content/fysica/grootheden_eenheden/eenheden_omzetten/exercises/minNaarUur";
import MlNaarDl from "../../../../content/fysica/grootheden_eenheden/eenheden_omzetten/drawings/ml_naar_dl";
import HPaNaarKPa from "../../../../content/fysica/grootheden_eenheden/eenheden_omzetten/exercises/hPaNaarKPa";
import Cm2NaarDm2 from "../../../../content/fysica/grootheden_eenheden/eenheden_omzetten/drawings/cm2NaarDm2";
import M3NaarDm3 from "../../../../content/fysica/grootheden_eenheden/eenheden_omzetten/exercises/m3NaarDm3";
import DirkVultBad from "../../../../content/fysica/grootheden_eenheden/eenheden_omzetten/drawings/dirkVultBad";
import { DrieKubussen, DrieKubussenMetVolume, DrieKubussenMetMelk, Dm3IsMelk } from "../../../../content/fysica/grootheden_eenheden/eenheden_omzetten/drawings/literIsDm3";
import MlNaarCm3 from "../../../../content/fysica/grootheden_eenheden/eenheden_omzetten/exercises/mlNaarCm3";
import DirkReadyToSprint from "../../../../content/fysica/grootheden_eenheden/eenheden_omzetten/drawings/dirkSprint";
import KgPerM3NaarGPerCm3 from "../../../../content/fysica/grootheden_eenheden/eenheden_omzetten/exercises/kgPerM3NaarGPerCm3";
import * as React from 'react';
export default {
  WandelingInPark,
  DirkKruist,
  WatIs1m84,
  MeterIs100Cm,
  DirkInCm,
  MeterNaarDecimeter,
  DirkZegtTijd,
  MinNaarUur,
  MlNaarDl,
  HPaNaarKPa,
  Cm2NaarDm2,
  M3NaarDm3,
  DirkVultBad,
  DrieKubussen,
  DrieKubussenMetVolume,
  DrieKubussenMetMelk,
  Dm3IsMelk,
  MlNaarCm3,
  DirkReadyToSprint,
  KgPerM3NaarGPerCm3,
  React
};