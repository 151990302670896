exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-trakteer-tsx": () => import("./../../../src/pages/trakteer.tsx" /* webpackChunkName: "component---src-pages-trakteer-tsx" */),
  "component---src-templates-all-courses-tsx": () => import("./../../../src/templates/allCourses.tsx" /* webpackChunkName: "component---src-templates-all-courses-tsx" */),
  "component---src-templates-chapter-tsx": () => import("./../../../src/templates/chapter.tsx" /* webpackChunkName: "component---src-templates-chapter-tsx" */),
  "component---src-templates-course-tsx": () => import("./../../../src/templates/course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */),
  "component---src-templates-lesson-bare-tsx": () => import("./../../../src/templates/lesson_bare.tsx" /* webpackChunkName: "component---src-templates-lesson-bare-tsx" */),
  "component---src-templates-lesson-tsx": () => import("./../../../src/templates/lesson.tsx" /* webpackChunkName: "component---src-templates-lesson-tsx" */)
}

