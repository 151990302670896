import { getRandomInt } from "./number";

export function getRandomArrElement<T>(arr: Array<T>) {
    return arr[getRandomInt(arr.length)];
}

export function shuffle<T>(array: Array<T>) {
    var currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
    
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
    }

    return array;
}