import React from "react";
import withSizePositionAngle from "components/withSizePositionAngle";


const _Bomen = () => {
    return (
        <g transform="translate(-946.101430 -635.789520)">
          <g transform="translate(0, 56)">
            <g>
              <path id="path7517" d="m 1271.4559,908.02178 c 0,0 44.5893,37.17297 44.5893,173.84632 7.3432,4.9202 22.5468,4.3774 25.1694,4.3774 2.6226,0 17.8363,0.5425 25.1795,-4.3774 0,-136.67335 44.5893,-173.84632 44.5893,-173.84632 h -33.9394 c -8.3834,12.28834 -16.3154,27.91245 -23.7595,47.56803 v -45.92122 h -23.5997 v 45.92122 c -7.9239,-19.61525 -15.3959,-35.29081 -23.7093,-47.56803 z" fill="#552200" strokeWidth="1.39398"/>
              <path id="path7515" d="m 1341.2146,579.78952 c -51.2518,0 -59.2565,38.95331 -59.2565,38.95331 -60.8613,-31.16051 -128.6578,30.04747 -97.1593,95.70697 -61.9293,11.6852 -68.3366,87.91525 -6.9414,111.84207 -21.3549,70.11084 37.9005,104.05429 92.3552,84.57895 h 42.8903 c 9.0782,1.67067 16.3764,1.67615 16.3764,1.67615 v 0 h 24.0202 v 0 c 0,0 6.9852,-0.004 16.1424,-1.67615 h 42.5847 c 54.4549,20.03176 113.7102,-14.46811 92.3554,-84.57895 61.3951,-23.92682 54.9877,-100.15211 -6.9415,-112.39377 31.4984,-65.10289 -36.298,-126.31578 -97.1593,-95.15527 0,0 -8.0149,-38.95331 -59.2666,-38.95331 z" fill="#66ad5b" fillOpacity="1" strokeWidth="1.41883"/>
            </g>
            <g>
              <path id="path7517-3" d="m 1060.3708,921.46271 c 0,0 35.9761,37.17297 35.9761,173.84629 5.9248,4.9202 18.1915,4.3774 20.3075,4.3774 2.116,0 14.3909,0.5425 20.3157,-4.3774 0,-136.67332 35.976,-173.84629 35.976,-173.84629 h -27.3834 c -6.764,12.28832 -13.1638,27.91245 -19.17,47.56801 v -45.92123 h -19.041 v 45.92123 c -6.3932,-19.61524 -12.4219,-35.29081 -19.1294,-47.56801 z" fill="#552200" strokeWidth="1.39398"/>
              <path id="path7515-6" d="m 1116.6543,645.78711 c -42.3776,0 -48.9963,33.8087 -48.9963,33.8087 -50.3231,-27.04503 -106.3804,26.0792 -80.33605,83.06708 -51.20626,10.14202 -56.50389,76.30415 -5.73936,97.07096 -17.65738,60.85151 31.33791,90.31203 76.36361,73.40872 h 35.4639 c 7.5063,1.45112 13.5409,1.45557 13.5409,1.45557 v 0 h 19.861 v 0 c 0,0 5.7757,-0.005 13.3472,-1.45557 h 35.2113 c 45.026,17.38623 94.0211,-12.55721 76.3638,-73.40872 50.7646,-20.76681 45.4667,-86.92479 -5.7395,-97.54973 26.0445,-56.50481 -30.0129,-109.63334 -80.336,-82.58831 0,0 -6.6271,-33.8087 -49.0045,-33.8087 z" fill="#2e7123" fillOpacity="1" strokeWidth="1.41883"/>
            </g>
            <g>
              <path id="path7517-3-1" d="m 1181.7962,955.99781 c 0,0 34.5691,36.86082 34.5691,172.38649 5.6931,4.8789 17.48,4.3406 19.5133,4.3406 2.0333,0 13.828,0.538 19.5211,-4.3406 0,-135.52567 34.5691,-172.38649 34.5691,-172.38649 h -26.3125 c -6.4995,12.18513 -12.649,27.67806 -18.4203,47.16859 v -45.53564 h -18.2963 v 45.53564 c -6.1432,-19.45055 -11.936,-34.99448 -18.3813,-47.16859 z" fill="#552200" strokeWidth="1.39398"/>
              <path id="path7515-6-1" d="m 1235.8785,682.63711 c -40.7202,0 -47.0801,33.52481 -47.0801,33.52481 -48.355,-26.81793 -102.22,25.86021 -77.1942,82.36954 -49.2036,10.05686 -54.2941,75.66341 -5.5149,96.25583 -16.9668,60.34054 30.1123,89.55367 73.3771,72.79229 h 34.077 c 7.2127,1.43894 13.0112,1.44335 13.0112,1.44335 v 0 h 19.0843 v 0 c 0,0 5.5499,-0.005 12.8253,-1.44335 h 33.8341 c 43.2651,17.24024 90.3441,-12.45175 73.3774,-72.79229 48.7792,-20.59242 43.6884,-86.19486 -5.515,-96.73057 25.0259,-56.03033 -28.8393,-108.71273 -77.1943,-81.8948 0,0 -6.3678,-33.52481 -47.0879,-33.52481 z" fill="#418036" fillOpacity="1" strokeWidth="1.41883"/>
            </g>
          </g>
        </g>
    );
}

const Bomen = withSizePositionAngle(_Bomen, 601.391651, 552.952971);

export default Bomen;
