import React from "react";
import withSizePositionAngle from "components/withSizePositionAngle";


const _Wolken = () => {
   return (
        <g transform="translate(-350.745010 -327.849100)">
          <g>
            <path d="m 436.47127,422.77291 a 50.158831,50.158831 0 0 0 -50.15836,50.15839 50.158831,50.158831 0 0 0 0.0943,3.07132 34.801878,34.801878 0 0 0 -0.86047,-0.86046 34.801878,34.801878 0 0 0 -34.80173,34.80174 34.801878,34.801878 0 0 0 34.80173,34.80175 h 118.86156 0.0972 a 38.836909,38.836909 0 0 0 38.83547,-38.83551 38.836909,38.836909 0 0 0 -38.83549,-38.83799 38.836909,38.836909 0 0 0 -17.89827,4.37197 50.158831,50.158831 0 0 0 -50.13594,-48.67121 z" fill="#cdecff" stroke="none" strokeWidth="9.99999" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" strokeDashoffset="37.7986" paintOrder="markers stroke fill"/>
            <path d="m 1344.5082,327.8491 a 70.74746,70.74746 0 0 0 -70.7469,70.74683 70.74746,70.74746 0 0 0 0.1331,4.33201 49.086957,49.086957 0 0 0 -1.2136,-1.21366 49.086957,49.086957 0 0 0 -49.0868,49.08678 49.086957,49.086957 0 0 0 49.0868,49.08676 h 167.6504 0.1372 a 54.778245,54.778245 0 0 0 54.7762,-54.77625 54.778245,54.778245 0 0 0 -54.7762,-54.77978 54.778245,54.778245 0 0 0 -25.2449,6.16654 70.74746,70.74746 0 0 0 -70.7152,-68.64923 z" fill="#cdecff" stroke="none" strokeWidth="9.99999" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" strokeDashoffset="37.7986" paintOrder="markers stroke fill"/>
            <path fill="#cdecff" stroke="#f4f4f4" strokeWidth="9.99999" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" strokeDashoffset="37.7986" paintOrder="markers stroke fill" d="m 1582.1039,367.72121 a 70.74746,70.74746 0 0 0 -70.7469,70.74684 70.74746,70.74746 0 0 0 0.133,4.332 49.086957,49.086957 0 0 0 -1.2136,-1.21366 49.086957,49.086957 0 0 0 -49.0868,49.08678 49.086957,49.086957 0 0 0 49.0868,49.08677 h 167.6506 0.1372 a 54.778245,54.778245 0 0 0 54.7763,-54.77626 54.778245,54.778245 0 0 0 -54.7763,-54.77978 54.778245,54.778245 0 0 0 -25.2448,6.16653 70.74746,70.74746 0 0 0 -70.7154,-68.64922 z"/>
            <path fill="#cdecff" stroke="#f4f4f4" strokeWidth="9.99999" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" strokeDashoffset="37.7986" paintOrder="markers stroke fill" d="m 1060.7712,354.92838 a 107.1013,107.1013 0 0 0 -106.06592,94.55711 89.07878,89.07878 0 0 0 -66.72707,86.22988 89.07878,89.07878 0 0 0 89.07814,89.07806 h 272.85005 a 79.823768,79.823768 0 0 0 79.8212,-79.82475 79.823768,79.823768 0 0 0 -68.3125,-78.98646 84.029483,84.029483 0 0 0 -82.4063,-67.60394 84.029483,84.029483 0 0 0 -28.3702,4.93534 107.1013,107.1013 0 0 0 -89.5728,-48.38524 107.1013,107.1013 0 0 0 -0.2944,0 z"/>
            <path fill="#cdecff" stroke="#f4f4f4" strokeWidth="9.99999" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" strokeDashoffset="37.7986" paintOrder="markers stroke fill" d="m 1813.9278,469.93126 a 84.111045,84.111045 0 0 0 -84.1111,84.11119 84.111045,84.111045 0 0 0 0.158,5.14931 58.359061,58.359061 0 0 0 -1.4417,-1.44167 58.359061,58.359061 0 0 0 -58.3576,58.36113 58.359061,58.359061 0 0 0 58.3576,58.35764 h 199.4795 a 65.125382,65.125382 0 0 0 65.1274,-65.12398 65.125382,65.125382 0 0 0 -65.1274,-65.1275 65.125382,65.125382 0 0 0 -30.012,7.3311 84.111045,84.111045 0 0 0 -84.0725,-81.61722 z"/>
          </g>
          <g transform="translate(1642.394890 50)">
            <g transform="translate(400 50)">
              <path d="m 436.47127,422.77291 a 50.158831,50.158831 0 0 0 -50.15836,50.15839 50.158831,50.158831 0 0 0 0.0943,3.07132 34.801878,34.801878 0 0 0 -0.86047,-0.86046 34.801878,34.801878 0 0 0 -34.80173,34.80174 34.801878,34.801878 0 0 0 34.80173,34.80175 h 118.86156 0.0972 a 38.836909,38.836909 0 0 0 38.83547,-38.83551 38.836909,38.836909 0 0 0 -38.83549,-38.83799 38.836909,38.836909 0 0 0 -17.89827,4.37197 50.158831,50.158831 0 0 0 -50.13594,-48.67121 z" fill="#cdecff" stroke="none" strokeWidth="9.99999" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" strokeDashoffset="37.7986" paintOrder="markers stroke fill"/>
            </g>
            <g transform="translate(-100 -125)">
              <path d="m 1344.5082,327.8491 a 70.74746,70.74746 0 0 0 -70.7469,70.74683 70.74746,70.74746 0 0 0 0.1331,4.33201 49.086957,49.086957 0 0 0 -1.2136,-1.21366 49.086957,49.086957 0 0 0 -49.0868,49.08678 49.086957,49.086957 0 0 0 49.0868,49.08676 h 167.6504 0.1372 a 54.778245,54.778245 0 0 0 54.7762,-54.77625 54.778245,54.778245 0 0 0 -54.7762,-54.77978 54.778245,54.778245 0 0 0 -25.2449,6.16654 70.74746,70.74746 0 0 0 -70.7152,-68.64923 z" fill="#cdecff" stroke="none" strokeWidth="9.99999" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" strokeDashoffset="37.7986" paintOrder="markers stroke fill"/>
            </g>
            <path fill="#cdecff" stroke="#f4f4f4" strokeWidth="9.99999" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" strokeDashoffset="37.7986" paintOrder="markers stroke fill" d="m 1582.1039,367.72121 a 70.74746,70.74746 0 0 0 -70.7469,70.74684 70.74746,70.74746 0 0 0 0.133,4.332 49.086957,49.086957 0 0 0 -1.2136,-1.21366 49.086957,49.086957 0 0 0 -49.0868,49.08678 49.086957,49.086957 0 0 0 49.0868,49.08677 h 167.6506 0.1372 a 54.778245,54.778245 0 0 0 54.7763,-54.77626 54.778245,54.778245 0 0 0 -54.7763,-54.77978 54.778245,54.778245 0 0 0 -25.2448,6.16653 70.74746,70.74746 0 0 0 -70.7154,-68.64922 z"/>
            <path fill="#cdecff" stroke="#f4f4f4" strokeWidth="9.99999" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" strokeDashoffset="37.7986" paintOrder="markers stroke fill" d="m 1060.7712,354.92838 a 107.1013,107.1013 0 0 0 -106.06592,94.55711 89.07878,89.07878 0 0 0 -66.72707,86.22988 89.07878,89.07878 0 0 0 89.07814,89.07806 h 272.85005 a 79.823768,79.823768 0 0 0 79.8212,-79.82475 79.823768,79.823768 0 0 0 -68.3125,-78.98646 84.029483,84.029483 0 0 0 -82.4063,-67.60394 84.029483,84.029483 0 0 0 -28.3702,4.93534 107.1013,107.1013 0 0 0 -89.5728,-48.38524 107.1013,107.1013 0 0 0 -0.2944,0 z"/>
            <path fill="#cdecff" stroke="#f4f4f4" strokeWidth="9.99999" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" strokeDashoffset="37.7986" paintOrder="markers stroke fill" d="m 1813.9278,469.93126 a 84.111045,84.111045 0 0 0 -84.1111,84.11119 84.111045,84.111045 0 0 0 0.158,5.14931 58.359061,58.359061 0 0 0 -1.4417,-1.44167 58.359061,58.359061 0 0 0 -58.3576,58.36113 58.359061,58.359061 0 0 0 58.3576,58.35764 h 199.4795 a 65.125382,65.125382 0 0 0 65.1274,-65.12398 65.125382,65.125382 0 0 0 -65.1274,-65.1275 65.125382,65.125382 0 0 0 -30.012,7.3311 84.111045,84.111045 0 0 0 -84.0725,-81.61722 z"/>
          </g>
        </g>
    );
}

const Wolken = withSizePositionAngle(_Wolken, 2*1642.394890, 346.619760);

export default Wolken;
