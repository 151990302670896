import React from "react";
import withSizePositionAngle from "components/withSizePositionAngle";


const _Bank = () => {
    return (
        <g transform="translate(-1525.860210 -1016.613411)">
          <g fill="#000000" transform="matrix(0.416214 0 0 0.416214 1143.14 1024.89)">
            <g transform="matrix(0.778183 0 0 0.777351 381.481 15.7065)" strokeWidth="1.28573">
              <path strokeWidth="1.28573" d="m 1757.7881,305.07654 h 38.9856" id="path8488"/>
              <path strokeWidth="1.28573" d="M 831.89455,305.07654 H 1725.2985" id="path8327"/>
              <path strokeWidth="1.28573" d="m 760.4193,305.07654 h 38.98561" id="path8200"/>
              <path id="rect8806" fill="#d3d3d3" fillOpacity="1" stroke="none" strokeWidth="6.17823" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" strokeDasharray="none" paintOrder="markers fill stroke" stopColor="#000000" d="m 867.88165,32.385448 h 31.98018 V 238.32809 h -31.98018 z m 789.44035,0.0051 h 31.9893 V 238.33828 H 1657.322 Z M 727.93455,305.07165 V 564.96433 H 760.4193 V 305.07654 m 38.98561,0 v 175.42548 h 32.48964 V 305.07654 m 893.40395,0 v 175.42548 h 32.4896 V 305.07654 m 38.9856,0 v 259.89268 h 32.4848 V 305.07654"/>
              <path id="path8615" fill="#ac6333" fillOpacity="1" stroke="none" strokeWidth="2.57146" strokeMiterlimit="4" strokeDasharray="none" transform="matrix(2.40261 0 0 2.40261 -1812.17 -1617.98)" d="m 1073.4531,654.36914 c -6.7196,0 -12.1718,5.45031 -12.1719,12.16992 v 14.19532 c 0,6.7216 5.4523,12.16992 12.1719,12.16992 h 42.0215 13.3106 v 0.002 h 315.2656 v 0.002 h 13.3144 42.0215 c 6.7196,0 12.168,-5.4503 12.168,-12.16992 v -14.19922 c 0,-6.71961 -5.4504,-12.16992 -12.168,-12.16992 z m 0,48.67774 c -6.7196,0 -12.1718,5.44639 -12.1719,12.16601 v 14.19922 c 0,6.72165 5.4523,12.16992 12.1719,12.16992 h 42.0215 13.3106 v 0.004 h 315.2656 13.3144 42.0215 c 6.7196,0 12.168,-5.45031 12.168,-12.16992 V 715.2168 c 0,-6.71962 -5.4504,-12.16602 -12.168,-12.16602 h -42.0215 v -0.002 h -13.3144 -315.2656 -13.3106 v -0.002 z m 3.584,68.83789 c -1.656,0 -3.3118,0.15846 -4.8945,0.44531 -1.5869,0.28888 -3.0981,0.70396 -4.4571,1.2207 -1.363,0.51877 -2.578,1.13464 -3.5585,1.82227 -0.9847,0.68966 -1.7346,1.4543 -2.17,2.25586 l -19.4101,15.79687 c -0.5127,0.94803 -0.6358,1.8595 -0.416,2.6875 0.2217,0.83411 0.7955,1.58793 1.6601,2.22266 0.8687,0.63676 2.0374,1.15179 3.4473,1.50781 1.4139,0.35805 3.0746,0.55859 4.9219,0.55859 h 5.0664 l 458.3867,0.002 h 5.0586 c 1.8533,0 3.5119,-0.20258 4.9258,-0.5586 1.4118,-0.35398 2.5824,-0.87104 3.4511,-1.50781 0.8646,-0.63473 1.4325,-1.38855 1.6563,-2.22266 0.2217,-0.828 0.1027,-1.73946 -0.4141,-2.6875 l -19.4121,-15.79687 c -0.4333,-0.80155 -1.1835,-1.5662 -2.1641,-2.25586 -0.9846,-0.68763 -2.1975,-1.3035 -3.5586,-1.82227 -1.3605,-0.5147 -2.8742,-0.93181 -4.4589,-1.2207 -1.5828,-0.28685 -3.2387,-0.44531 -4.8926,-0.44531 h -38.4395 -13.3144 v 0.002 h -315.2656 v -0.004 h -13.3106 z"/>
            </g>
          </g>
        </g>
    );
}

const Bank = withSizePositionAngle(_Bank, 380.363904, 197.606464);

export default Bank;
